import { gql, useMutation, useQuery } from '@apollo/client';
import {
  createContext,
  memo,
  ReactFragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import toast from 'react-hot-toast';

import {
  AccordionForwardRef,
  AccordionRef
} from '@components/common/Accordion';
import { paymentMethodsMap as registredPaymentMethods } from '@components/pages/Checkout/PaymentMethods';
import {
  GetSelectedAndAvailableShippingMethodsDocument,
  useSetShippingMethodOnCartMutation
} from '@generated/graphql';
import { useAdminUser, useCart, useRecurrence, useStoreCode } from '@hooks';
import { Persistence, scrollToNextElement } from '@utils';
import { first } from 'lodash';
import { getCardType, validateCCCode } from 'src/utils/creditCard';

export const AVAILABLE_PAYMENT_METHODS = gql`
  query AVAILABLE_PAYMENT_METHODS($cartId: String!) {
    cart(cart_id: $cartId) {
      available_payment_methods {
        code
        instructions
        title
        visible
        label_discount
        additional_info {
          public_key
          expiration
          installments {
            price
            value
          }
        }
      }
    }
  }
`;

export const SET_BILLING_ADDRESS_ON_CART = gql`
  mutation setBillingAddress(
    $cartId: String!
    $address: CartAddressInput
    $sameAsShipping: Boolean
    $customerAddressId: Int
  ) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cartId
        billing_address: {
          address: $address
          same_as_shipping: $sameAsShipping
          customer_address_id: $customerAddressId
        }
      }
    ) @connection(key: "setBillingAddressOnCart") {
      cart {
        id
        billing_address {
          customer_address_id
          firstname
          lastname
          country {
            code
          }
          street
          city
          region {
            code
          }
          postcode
          telephone
        }
      }
    }
  }
`;

export const SET_PAYMENT_METHOD_ON_CART = gql`
  mutation setPaymentMethodOnCart($cartId: String!, $code: String!) {
    setPaymentMethodOnCart(
      input: { cart_id: $cartId, payment_method: { code: $code } }
    ) @connection(key: "setPaymentMethodOnCart") {
      cart {
        id
        selected_payment_method {
          code
          title
        }
      }
    }
  }
`;

const SET_PAYMENT_METHOD_ON_CART_PAGBANK = gql`
  mutation setPaymentMethodOnCart(
    $cartId: String!
    $hash: String!
    $installments: Int!
    $fullname: String!
    $lastcardnumber: String!
  ) {
    setPaymentMethodOnCart(
      input: {
        cart_id: $cartId
        payment_method: {
          code: "pagbank_paymentmagento_cc"
          additional_information: {
            lastcardnumber: $lastcardnumber
            installments: $installments
            hash: $hash
            fullname: $fullname
          }
        }
      }
    ) {
      cart {
        prices {
          grand_total {
            value
            currency
          }

          discounts {
            label
            amount {
              value
              currency
            }
          }
        }

        selected_payment_method {
          code
          title
        }
        available_payment_methods {
          code
          title
        }
      }
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation placeOrder(
    $cartId: String!
    $adminUserId: String
    $isRecurrence: String
    $cycleRecurrence: String
    $gatewayRecurrence: String
  ) {
    placeOrder(
      input: {
        cart_id: $cartId
        admin_user_id: $adminUserId
        is_recurrence: $isRecurrence
        cycle_recurrence: $cycleRecurrence
        gateway_recurrence: $gatewayRecurrence
      }
    ) @connection(key: "placeOrder") {
      order {
        order_number
        billet_href
        billet_href_print
        billet_line_code
        boleto_line_code
        boleto_pdf_href
        moip_payment_links
        qr_code
        qr_code_url
        expiration_qrcode
        payment_link
      }
    }
  }
`;

// A query to fetch order details _right_ before we submit, so that we can pass
// data to the order confirmation page.
export const GET_ORDER_DETAILS = gql`
  query getOrderDetails($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      prices {
        grand_total {
          currency
          value
        }
        discount {
          label
          amount {
            value
            currency
          }
        }
      }
      selected_payment_method {
        code
        title
      }
      email
      total_quantity
      shipping_addresses {
        firstname
        lastname
        street
        city
        region {
          label
        }
        postcode
        country {
          label
        }

        selected_shipping_method {
          carrier_title
          method_title
          method_code
          carrier_code
          amount {
            currency
            value
          }
        }
      }
      available_payment_methods {
        code
        title
      }
      items {
        id
        product {
          id
          name
          thumbnail {
            url
          }
          sku
          max_sale_qty
          is_discount_recurrence
          url_key
          url_suffix
          small_image {
            url
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
          custom_brand
          custom_manufacturer
          custom_packing
          custom_format
          custom_weight
          custom_dimensions
          custom_flavor
          stock_status
          categories {
            id
            name
          }
          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            quantity
          }
          ... on ConfigurableProduct {
            variants {
              attributes {
                uid
              }
              product {
                id
                thumbnail {
                  url
                }
                max_sale_qty
                is_discount_recurrence
                small_image {
                  url
                }
                sku
                name
                stock_status
                price_tiers {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                  quantity
                }
                custom_brand
                custom_manufacturer
                custom_packing
                custom_format
                custom_weight
                custom_dimensions
                custom_flavor
                price_range {
                  minimum_price {
                    final_price {
                      value
                      currency
                    }
                  }
                }
                categories {
                  id
                  name
                }
              }
            }
          }
        }
        prices {
          price {
            currency
            value
          }
        }
        quantity
        ... on ConfigurableCartItem {
          configurable_options {
            id
            option_label
            value_id
            value_label
          }
        }
      }
      applied_coupons {
        name
        code
        description
      }
    }
  }
`;

interface iShippingMethods {
  amount: {
    value: number;
  };
  available: boolean;
  carrier_code: string;
  carrier_title: string;
  description: string;
  method_code: string;
  method_title: string;
}
export interface PaymentMethodType {
  code: string;
  instructions: any;
  title: string;
  label_discount: string;
  additional_info: {
    expiration: string | null;
    installments: Array<{
      price: number;
      value: number;
    }> | null;
    public_key: string;
  } | null;
}

export interface SaveAddressProps {
  id: number;
  firstname: string;
  lastname: string;
  street: string[];
  city: string;
  region: string;
  region_id: number;
  country_code: string;
  postcode: string;
  company: string;
  telephone: string;
  custom_address_type: string;
  save_in_address_book: boolean;
}
export interface AddressProps {
  __typename: string;
  id: number;
  city: string;
  country_code: string;
  default_shipping: boolean;
  default_billing: boolean;
  firstname: string;
  lastname: string;
  postcode: string;
  region: {
    __typename: string;
    region: string;
    region_code: string;
    region_id: number;
  };
  street: string;
  number?: string;
  complement?: string;
  district?: string;
  telephone: string;
  custom_address_type: string;
}

interface CheckoutProps {
  children?: ReactNode | ReactFragment;
}

export interface ICheckout {
  currentStep: number;
  setCurrentStep: (val: number) => void;
  updateHeaderCheckout: (
    step: 'cart' | 'address' | 'shippingMethod' | 'paymentMethod'
  ) => void;
  paymentMethodAccordionRef: AccordionRef;
  paymentMethodCloseAccordion(): void;
  paymentMethodCode: string;
  paymentMethodSelected: boolean;
  paymentMethodCodeSelected: string;
  paymentMethodCodeHandler(val: string): void;
  paymentMethodList: Array<PaymentMethodType>;
  customerShippingHandler(val: boolean): void;
  currentAddressId: number;
  setCurrentAddressId(value: number): void;
  customerShippingUsed: boolean;
  setHasAddress(payload: boolean): any;
  hasAddress: boolean;
  setHasDeliveryMethod(payload: boolean): any;
  hasDeliveryMethod: boolean;
  selectedAddress: { label: string; value: number };
  selectedAddressHandler(val: { label: string; value: number }): void;
  orderConfirmed: boolean;
  orderConfirmedHandler(val: boolean): void;
  setPaymentMethod(): Promise<void>;
  setBillingAddress(payload: {
    address?: SaveAddressProps;
    sameAsShipping?: boolean;
    customerAddressId?: number;
  }): Promise<void>;
  placeOrder(payload: {
    variables: {
      cartId: string;
      adminUserId?: string;
      isRecurrence?: string;
      cycleRecurrence?: string;
      gatewayRecurrence?: string;
    };
  }): Promise<any>;
  handleSubmitPaymentMethodForm(payload?: {
    additionalInformation?: { getValues?: any; pubKey?: string };
  }): Promise<void>;
  newBillingAddressFormValues: any;
  setNewBillingAddressFormValues: (val: any) => void;
  availableShippingMethods: iShippingMethods[];
  refetchShippingMethods(): Promise<any>;
  refetchPaymentMethods(): Promise<any>;
  handleCheckoutReset(): void;
  handleClearPaymentMethod(): void;
}

export const CheckoutContext = createContext({} as ICheckout);

export const CheckoutProvider = memo((props: CheckoutProps) => {
  const paymentMethodAccordionRef = useRef<AccordionForwardRef | null>(null);

  const [setShippingMethodOnCart] = useSetShippingMethodOnCartMutation();

  const { cart, isSignature, cartId, cartToken, fetchCart } = useCart();
  const { storeCode } = useStoreCode();
  const { isAdmin } = useAdminUser();
  const { recurrenceConfig } = useRecurrence();

  const { data: availableShippingMethods, refetch: refetchShippingMethods } =
    useQuery(GetSelectedAndAvailableShippingMethodsDocument, {
      variables: { cartId },
      skip: !cartId
    });
  const { data: availablePaymentMethods, refetch: refetchPaymentMethods } =
    useQuery(AVAILABLE_PAYMENT_METHODS, {
      variables: {
        cartId
      },
      skip: !cartId,
      fetchPolicy: 'no-cache'
    });

  const [placeOrder] = useMutation(PLACE_ORDER);
  const [setBillingAddressOnCart] = useMutation(SET_BILLING_ADDRESS_ON_CART);
  const [setPaymentMethodMutation] = useMutation(SET_PAYMENT_METHOD_ON_CART);
  const [setPaymentMethodPagBankMutation] = useMutation(
    SET_PAYMENT_METHOD_ON_CART_PAGBANK
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [hasAddress, setHasAddress] = useState(false);
  const [currentAddressId, setCurrentAddressId] = useState(0); // same_customer_address_id
  const [hasDeliveryMethod, setHasDeliveryMethod] = useState(false);
  const [customerShippingUsed, setCustomerShipingUsed] = useState(true);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
  const [paymentMethodCodeSelected, setPaymentMethodCodeSelected] =
    useState('');
  const [paymentMethodCode, setPaymentMethodCode] = useState('');
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({
    label: '',
    value: 0
  });
  const [newBillingAddressFormValues, setNewBillingAddressFormValues] =
    useState<any>(null);

  const updateHeaderCheckout = useCallback(
    (step: 'cart' | 'address' | 'shippingMethod' | 'paymentMethod') => {
      const steps = {
        cart: {
          conditions: [!hasAddress, !hasDeliveryMethod, !paymentMethodSelected],
          action: () => {
            setCurrentStep(1);
          }
        },
        address: {
          conditions: [!hasAddress, !hasDeliveryMethod, !paymentMethodSelected],
          action: () => {
            setCurrentStep(2);
          }
        },
        shippingMethod: {
          conditions: [hasAddress, !hasDeliveryMethod, !paymentMethodSelected],
          action: () => {
            setCurrentStep(3);
          }
        },
        paymentMethod: {
          conditions: [hasAddress, hasDeliveryMethod, paymentMethodSelected],
          action: () => {
            setCurrentStep(4);
          }
        }
      };

      const { conditions, action } = steps[step];
      if (conditions.every(cond => cond)) {
        action();
      }
    },
    [hasAddress, hasDeliveryMethod, paymentMethodSelected, setCurrentStep]
  );

  const paymentMethodListMemo = useMemo(() => {
    const paymentMethodsNames = Object.keys(registredPaymentMethods);

    return (
      availablePaymentMethods?.cart?.available_payment_methods?.filter(
        method => {
          if (isSignature) {
            return method.code === recurrenceConfig.payment_id;
          }

          // method.visible might return null and null means it should be ignored.
          if (method.visible === false) {
            return isAdmin;
          }

          if (method.code === 'backoffice_cc') {
            return isAdmin;
          }

          if (method.code === 'deuna_paymentlink' && !isSignature) {
            return isAdmin;
          }

          return paymentMethodsNames.includes(method.code);
        }
      ) || []
    );
  }, [
    availablePaymentMethods?.cart?.available_payment_methods,
    isSignature,
    recurrenceConfig.payment_id,
    isAdmin
  ]);

  const paymentMethodCloseAccordion = useCallback(() => {
    if (paymentMethodAccordionRef.current)
      paymentMethodAccordionRef.current?.close();
    setPaymentMethodSelected(true);
    setPaymentMethodCodeSelected(paymentMethodCode);
  }, [paymentMethodAccordionRef, paymentMethodCode]);

  const setBillingAddress = useCallback(
    async ({
      address,
      sameAsShipping,
      customerAddressId
    }: {
      address?: SaveAddressProps;
      sameAsShipping?: boolean;
      customerAddressId?: number | undefined;
    }) => {
      try {
        const cartCreated = await cartToken();
        await setBillingAddressOnCart({
          variables: {
            cartId: cartCreated,
            address,
            sameAsShipping,
            customerAddressId
          }
        });
      } catch (error) {
        toast.error(error?.message);
      }
    },
    [cartToken, setBillingAddressOnCart]
  );

  const setPaymentMethod = useCallback(
    async (additionalInformation?: { getValues?: any; pubKey?: string }) => {
      const cartCreated = await cartToken();
      switch (paymentMethodCode) {
        case 'pagbank_paymentmagento_cc': {
          const data = additionalInformation?.getValues();
          const cardNumber = data?.card_number?.replace(/\D/g, '');
          const cvcCard = data.cvv.replace(/\D/g, '');
          const [expirationMonth, expirationYear] =
            data.expiration_date.split('/');
          const cardType = getCardType(cardNumber);
          const IsInvalidCode = validateCCCode(
            cvcCard,
            cardType === 'american-express' ? 4 : 3
          );

          if (IsInvalidCode) {
            throw {
              name: 'InvalidPaymentMethod',
              message: IsInvalidCode.defaultMessage
            };
          }

          const { encryptCard } = window.PagSeguro;

          if (encryptCard) {
            const { encryptedCard } = await encryptCard({
              publicKey: additionalInformation?.pubKey || '',
              holder: data?.cardholder_name,
              number: cardNumber,
              expMonth: expirationMonth,
              expYear: `20${expirationYear}`,
              securityCode: cvcCard
            });

            await setPaymentMethodPagBankMutation({
              variables: {
                cartId: cartCreated,
                hash: encryptedCard,
                installments: data?.installments?.value,
                fullname: data?.cardholder_name,
                lastcardnumber: data?.card_number?.split(' ')[3]
              },

              refetchQueries: ['GetCartDetails']
            });
          }

          break;
        }
        default:
          await setPaymentMethodMutation({
            variables: {
              cartId: cartCreated,
              code: paymentMethodCode
            }
          });
          break;
      }
    },
    [
      cartToken,
      paymentMethodCode,
      setPaymentMethodMutation,
      setPaymentMethodPagBankMutation
    ]
  );

  const preselectPaymentMethod = useCallback(
    async code => {
      const cartCreated = await cartToken();
      switch (code) {
        case 'pagbank_paymentmagento_cc':
          await setPaymentMethodPagBankMutation({
            variables: {
              cartId: cartCreated,
              hash: '',
              installments: 1,
              fullname: '',
              lastcardnumber: ''
            },
            onCompleted: () => fetchCart()
          });
          break;
        default:
          await setPaymentMethodMutation({
            variables: {
              cartId: cartCreated,
              code: code
            },
            onCompleted: () => fetchCart()
          });
          break;
      }
    },
    [
      cartToken,
      fetchCart,
      setPaymentMethodMutation,
      setPaymentMethodPagBankMutation
    ]
  );

  const handleSubmitPaymentMethodForm = useCallback(
    async (data: { additionalInformation?: any }) => {
      const { carrier_code, method_code } =
        cart?.shipping_addresses[0]?.selected_shipping_method ?? {};
      const cartCreated = await cartToken();
      // setar endereço aqui
      if (customerShippingUsed) {
        await setBillingAddressOnCart({
          variables: {
            cartId: cartCreated,
            sameAsShipping: true,
            customerAddressId: currentAddressId
          }
        });
      }

      // After setting billing address the shipping method is cleared on the cart object
      // this sets the same shipping method again
      if (cartCreated && carrier_code && method_code) {
        await setShippingMethodOnCart({
          variables: {
            cartId: cartCreated,
            shippingMethod: {
              carrier_code,
              method_code
            }
          }
        });
      }

      try {
        await setPaymentMethod(data.additionalInformation);
        paymentMethodCloseAccordion();
        scrollToNextElement('payment-method-accordion', {
          offsetTop: 290,
          offsetHeight: 0,
          skipElementHeight: true
        });
      } catch (e) {
        toast.error(e.message);
      }
    },
    [
      cart,
      customerShippingUsed,
      setShippingMethodOnCart,
      cartToken,
      setPaymentMethod,
      paymentMethodCloseAccordion,
      setBillingAddressOnCart,
      currentAddressId
    ]
  );

  const paymentMethodCodeHandler = useCallback(
    async (val: string) => {
      setPaymentMethodCode(val);
      // pre-select payment method on click
      await preselectPaymentMethod(val);
    },
    [preselectPaymentMethod]
  );

  const handleClearPaymentMethod = useCallback(() => {
    paymentMethodCodeHandler('');
    setPaymentMethodCodeSelected('');
    setPaymentMethodSelected(false);
    paymentMethodAccordionRef.current?.open();
  }, [paymentMethodCodeHandler]);

  const customerShippingHandler = useCallback(
    (val: boolean) => {
      setCustomerShipingUsed(val);
    },
    [setCustomerShipingUsed]
  );

  const orderConfirmedHandler = useCallback(
    (val: boolean) => {
      setOrderConfirmed(val);
    },
    [setOrderConfirmed]
  );

  const selectedAddressHandler = useCallback(
    (val: { label: string; value: number }) => {
      setSelectedAddress(val);
      // setNewBillingAddressFormValues(null);
      if (val.value !== -1) {
        setBillingAddress({
          sameAsShipping: false,
          customerAddressId: val.value
        });
      }
    },
    [setBillingAddress]
  );

  const handleCheckoutReset = useCallback(() => {
    setHasAddress(false);
    setHasDeliveryMethod(false);
    orderConfirmedHandler(false);
    setCustomerShipingUsed(true);
    setPaymentMethodSelected(false);
    setNewBillingAddressFormValues(null);
    setSelectedAddress({ label: '', value: 0 });
  }, [orderConfirmedHandler]);

  const foundAvailableShippingMethods = useMemo(() => {
    const foundShippingAddress = first(
      availableShippingMethods?.cart?.shipping_addresses
    ) as {
      available_shipping_methods: iShippingMethods[];
    };

    return foundShippingAddress?.available_shipping_methods;
  }, [availableShippingMethods]);

  useEffect(() => {
    const currentCartId = Persistence.getItem('@cart/id');
    async function loadCartWhenStoreCodeChanges() {
      await fetchCart();
      await refetchShippingMethods();
      await fetchCart();
    }

    if (currentCartId) {
      loadCartWhenStoreCodeChanges();
    }
  }, [storeCode, refetchShippingMethods, fetchCart]);

  return (
    <CheckoutContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        updateHeaderCheckout,
        paymentMethodList: paymentMethodListMemo,
        paymentMethodCode,
        paymentMethodSelected,
        paymentMethodCodeSelected,
        paymentMethodCodeHandler,
        paymentMethodAccordionRef,
        customerShippingUsed,
        customerShippingHandler,
        selectedAddress,
        selectedAddressHandler,
        currentAddressId,
        setCurrentAddressId,
        paymentMethodCloseAccordion,
        orderConfirmed,
        orderConfirmedHandler,
        setPaymentMethod,
        setBillingAddress,
        placeOrder,
        handleSubmitPaymentMethodForm,
        setHasAddress,
        hasAddress,
        setHasDeliveryMethod,
        hasDeliveryMethod,
        newBillingAddressFormValues,
        setNewBillingAddressFormValues,
        availableShippingMethods: foundAvailableShippingMethods,
        refetchShippingMethods,
        handleCheckoutReset,
        handleClearPaymentMethod,
        refetchPaymentMethods
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
});

CheckoutProvider.displayName = 'CheckoutProvider';
