import ArrowSVG from '@assets/icons/arrow-point-left.svg';
import { ErrorMessage, Input, Select } from '@components/common';
import { Checkbox } from '@components/common/Checkbox';
import {
  useCreateCustomerAddressMutation,
  useGetCustomAddressTypeQueryQuery,
  useGetRegionsQuery,
  useUpdateCustomerAddressMutation
} from '@generated/graphql';
import { useZipcode } from '@hooks';
import { extractNumbers } from '@utils';
import axios from 'axios';
import classNames from 'classnames';
import { first, isEmpty } from 'lodash';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

interface iAddressFormData {
  custom_address_type: {
    __typename?: string;
    value: string;
    label: string;
  };
  postcode: string;
  street: string;
  number: number;
  noNumber: boolean;
  complement: string;
  district: string;
  city: string;
  state: {
    value: number;
    label: string;
  };
  telephone: string;
  firstname: string;
  lastname: string;
  default_shipping: boolean;
  default_billing: boolean;
  country_code: string;
}

export interface SaveAddressProps {
  __typename: string;
  id: number;
  city: string;
  country_code: string;
  default_shipping: boolean;
  default_billing: boolean;
  firstname: string;
  lastname: string;
  postcode: string;
  region: string | number | undefined;
  street: string;
  number: string;
  district: string;
  complement: string;
  telephone: string;
  custom_address_type: string;
}

export interface iFormAddress {
  onFinish?: ([any]?: any) => void;
  data?: SaveAddressProps;
  useCurrentZipcode?: boolean;
  isCustomerAddressesPage?: boolean;
  hasAddresses?: boolean;
}

export const customAddressQueryParams = {
  attribute_code: 'custom_address_type',
  entity_type: 'customer_address'
};

const getAddressForCep = (cep: string) => {
  return axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
};

export const FormAddress = ({
  data = {} as SaveAddressProps,
  onFinish,
  useCurrentZipcode = false,
  isCustomerAddressesPage = false,
  hasAddresses = false
}: iFormAddress) => {
  const pathname = usePathname();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    trigger
  } = useForm({
    defaultValues: {
      default_shipping: !hasAddresses
    } as iAddressFormData,
    reValidateMode: 'onChange'
  });

  const noNumberData = watch('noNumber');

  const { zipcode } = useZipcode();
  const [updateAddress] = useUpdateCustomerAddressMutation();
  const [createAddress] = useCreateCustomerAddressMutation();

  const { data: regions } = useGetRegionsQuery({
    variables: {
      countryCode: 'BR'
    }
  });
  const { data: addressTypes } = useGetCustomAddressTypeQueryQuery({
    variables: customAddressQueryParams as never,
    onCompleted: addressTypes => {
      setValue(
        'custom_address_type',
        addressTypes?.customAttributeMetadata.items[0].attribute_options[0]
      );
    }
  });

  const customerAddressTypeOptions = useMemo(
    () =>
      addressTypes?.customAttributeMetadata.items[0].attribute_options || [],
    [addressTypes?.customAttributeMetadata.items]
  );

  const regionsOptions = useMemo(
    () => regions?.country?.available_regions || [],
    [regions]
  );

  const handleSubmitFormAddress = useCallback(
    async (formData: iAddressFormData) => {
      const region = (id: number) => {
        const value = regionsOptions.find(item => item.id === id);
        return {
          region: value?.name,
          region_code: value?.code,
          region_id: value?.id
        };
      };

      if (data?.id) {
        await updateAddress({
          variables: {
            addressId: data?.id as number,
            address: {
              custom_address_type: formData.custom_address_type.value,
              postcode: formData.postcode,
              street: [
                formData?.street,
                formData?.noNumber ? 'sn' : formData?.number,
                formData?.complement || '',
                formData?.district
              ],
              city: formData.city,
              region: region(formData.state.value) as any,
              telephone: formData.telephone,
              firstname: formData.firstname,
              lastname: formData.lastname,
              default_shipping: formData.default_shipping
                ? formData.default_shipping
                : false,
              default_billing: formData.default_billing
                ? formData.default_billing
                : false,
              country_code: 'BR' as any
            } as any
          },
          onCompleted: () => {
            onFinish && onFinish({ newAddressId: false });
          }
        });
      } else {
        await createAddress({
          variables: {
            address: {
              custom_address_type: formData.custom_address_type.value,
              postcode: formData.postcode,
              city: formData.city,
              region: region(formData?.state.value) as any,
              telephone: formData.telephone,
              firstname: formData.firstname,
              lastname: formData.lastname,
              default_shipping: formData.default_shipping
                ? formData.default_shipping
                : false,
              default_billing: formData.default_billing
                ? formData.default_billing
                : false,
              country_code: 'BR',
              street: [
                formData.street,
                formData?.noNumber ? 'sn' : formData.number,
                formData.complement,
                formData.district
              ]
            } as any
          },
          onCompleted: ({ createCustomerAddress: { id: newAddressId } }) => {
            onFinish &&
              onFinish({
                newAddressId
              });
          }
        });
      }

      reset();
    },
    [createAddress, reset, data?.id, onFinish, regionsOptions, updateAddress]
  );

  const postcodeHandler = useCallback(
    async (val: string) => {
      const cep = val.replaceAll('_', '').replaceAll('-', '');
      if (cep.length === 8) {
        await getAddressForCep(cep).then(({ data: postCodeData }) => {
          const regionValue = (code: string) => {
            const reg = regionsOptions.find(item => item.code === code);

            return { value: reg?.id, label: reg?.name };
          };

          new Map(
            Object.entries({
              postcode: postCodeData?.cep,
              city: postCodeData?.city,
              street: postCodeData?.street,
              district: postCodeData?.neighborhood,
              state: regionValue(postCodeData?.state)
            })
          ).forEach((val, key: any) => setValue(key, val));
        });
      }

      trigger();
    },
    [regionsOptions, setValue, trigger]
  );

  useEffect(() => {
    if (
      !isEmpty(data) &&
      !isEmpty(customerAddressTypeOptions) &&
      !isEmpty(regionsOptions)
    ) {
      const regionValue = (code: string | number) => {
        const reg = regionsOptions.find(item => item.id === code);

        return { value: reg?.id, label: reg?.name };
      };

      new Map(
        Object.entries({
          postcode: data?.postcode || '',
          custom_address_type:
            customerAddressTypeOptions.find(
              item =>
                item?.label?.toLowerCase() ===
                data?.custom_address_type?.toLowerCase()
            ) || first(customerAddressTypeOptions),
          city: data?.city,
          street: data?.street,
          number: data?.number,
          district: data?.district,
          complement: data?.complement || '',
          state: regionValue(data?.region),
          telephone: data?.telephone || '',
          firstname: data?.firstname,
          lastname: data?.lastname,
          default_shipping: data?.default_shipping || '',
          default_billing: data?.default_billing || '',
          country_code: 'BR' as any
        })
      ).forEach((val, key: any) => setValue(key, val));
    }
  }, [customerAddressTypeOptions, data, regionsOptions, setValue]);

  useEffect(() => {
    if (useCurrentZipcode) postcodeHandler(zipcode);
  }, [useCurrentZipcode, postcodeHandler, zipcode]);

  return (
    <form
      className="width-full grid grid-cols-12 md:gap-x-12 gap-2 border-box pb-12"
      onSubmit={handleSubmit(handleSubmitFormAddress)}
    >
      <div className="col-span-12">
        <div className="mb-[2.4px] text-left text-sm text-gray-dark font-bold">
          <label>Tipo de Endereço</label>
        </div>

        <Controller
          name="custom_address_type"
          control={control}
          rules={{ required: 'Este é um campo obrigatório.' }}
          render={({ field }) => (
            <Select
              {...field}
              options={customerAddressTypeOptions}
              styles={{
                control: styles => ({
                  ...styles,
                  borderColor: errors.custom_address_type && 'red!important'
                })
              }}
            />
          )}
        />

        {errors.custom_address_type && (
          <>
            {errors?.custom_address_type && (
              <ErrorMessage error="Este é um campo obrigatório." />
            )}
          </>
        )}
      </div>
      <div className="col-span-12 md:col-span-6">
        <div className="mt-[15px] text-left text-sm font-light text-gray-dark">
          <label>CEP:</label>
        </div>

        <div className="flex w-full">
          <Controller
            name="postcode"
            control={control}
            rules={{
              required: 'Este é um campo obrigatório'
            }}
            render={({ field: { ref, ...field } }) => {
              return (
                <ReactInputMask
                  {...field}
                  inputRef={ref}
                  onBlur={async () => {
                    field.onBlur();

                    if (extractNumbers(field.value).length === 8) {
                      const { data } = await getAddressForCep(field.value);
                      setValue('state', data?.state);
                      setValue('city', data?.city);
                      setValue('district', data?.neighborhood);
                      setValue('street', data?.street);
                    }
                  }}
                  className={`outline-none px-3 py-1 h-12 w-full rounded border border-gray-light focus:border-primary-light border-r-0 rounded-r-none ${
                    errors.postcode
                      ? 'border-error-medium focus:active:border-error-medium'
                      : 'border-gray-light '
                  }`}
                  type="text"
                  id="postcode"
                  mask="99999-999"
                />
              );
            }}
          />

          <button
            type="button"
            className="h-[inherit] w-[auto] bg-primary-medium rounded rounded-l-none mr-2"
            onClick={() => postcodeHandler(getValues()['postcode'])}
          >
            <div className="rotate-180 px-4">
              <ArrowSVG color="#ffffff" />
            </div>
          </button>
        </div>
      </div>
      <div className="col-span-6">
        <div className="h-[100%] flex items-end relative">
          <a
            className="text-primary-medium font-bold cursor-pointer underline"
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            target="_blank"
            rel="noreferrer"
          >
            Não sabe seu CEP?
          </a>
        </div>
      </div>
      <div className="col-span-12">
        <Input
          type="text"
          label="Endereço"
          id="street"
          register={register}
          errors={errors}
          validations={{
            required: 'Este é um campo obrigatório.'
          }}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <Input
          type="number"
          label="Número"
          id="number"
          min={1}
          register={register}
          disabled={noNumberData}
          validations={{
            required: noNumberData ? '' : 'Este é um campo obrigatório.'
          }}
          errors={errors}
        />
      </div>
      <div
        className={`col-span-12 md:col-span-6 ${errors?.number ? 'my-auto' : 'mt-auto'} h-[48px]`}
      >
        <div className="flex items-center h-full">
          <Controller
            name="noNumber"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={!!field.value}
                onChange={() => {
                  setValue('noNumber', !field.value);
                  trigger();
                }}
                id="noNumber"
              />
            )}
          />
          <div className="ml-2 font-light text-gray-dark text-sm">
            <label htmlFor="noNumber">Sem número</label>
          </div>
        </div>
      </div>
      <div className="col-span-12 md:col-span-6">
        <Input
          type="text"
          label="Complemento"
          id="complement"
          register={register}
          maxLength={50}
          errors={errors}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <Input
          type="text"
          label="Bairro"
          id="district"
          register={register}
          validations={{ required: 'Este é um campo obrigatório.' }}
          errors={errors}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <Input
          type="text"
          label="Cidade"
          id="city"
          register={register}
          validations={{ required: 'Este é um campo obrigatório.' }}
          errors={errors}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <div
          className={classNames(
            'mt-[15px] text-left text-sm font-bold text-gray-dark',
            errors.state?.message && 'text-error-medium'
          )}
        >
          <label>Estado</label>
        </div>
        <Controller
          name="state"
          control={control}
          rules={{
            required: 'Este é um campo obrigatório'
          }}
          render={({ field }) => {
            return (
              <Select
                {...field}
                placeholder="Selecionar estado"
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: errors.state && 'red!important'
                  })
                }}
                options={regionsOptions.map(item => ({
                  value: item.id,
                  label: item.name
                }))}
              />
            );
          }}
        />
        {errors.state && <ErrorMessage error={errors?.state?.message} />}
      </div>
      <div className="col-span-12 md:col-span-6">
        <Input
          type="text"
          label="Telefone do destinatário"
          mask="(99) 99999-9999"
          id="telephone"
          defaultValue={data?.telephone}
          register={register}
          validations={{ required: 'Este é um campo obrigatório.' }}
          errors={errors}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <Input
          type="text"
          label="Nome do destinatário"
          id="firstname"
          register={register}
          validations={{ required: 'Este é um campo obrigatório.' }}
          errors={errors}
        />
      </div>
      <div className="col-span-12 md:col-span-6">
        <Input
          type="text"
          label="Sobrenome"
          id="lastname"
          register={register}
          validations={{ required: 'Este é um campo obrigatório.' }}
          errors={errors}
        />
      </div>
      <div className="col-span-12">
        <div className="flex items-center py-1">
          <div className="mr-2">
            <Controller
              name="default_shipping"
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    {...field}
                    checked={!!field.value}
                    id="default_shipping"
                  />
                );
              }}
            />
          </div>
          <div className="ml-2">
            <label htmlFor="default_shipping">
              Definir como endereço de entrega padrão
            </label>
          </div>
        </div>
        {(data?.id || pathname !== '/cart') && (
          <div className="flex items-center py-1">
            <div className="mr-2">
              <Controller
                name="default_billing"
                control={control}
                render={({ field }) => {
                  return (
                    <Checkbox
                      {...field}
                      checked={!!field.value}
                      id="default_billing"
                    />
                  );
                }}
              />
            </div>
            <div className="ml-2">
              <label htmlFor="default_billing">
                Definir como endereço de cobrança padrão
              </label>
            </div>
          </div>
        )}
      </div>
      <div
        className={
          isCustomerAddressesPage
            ? 'col-span-12 md:col-span-3 md:col-start-7'
            : 'col-span-12 md:col-span-6'
        }
      >
        {hasAddresses && (
          <div className={isCustomerAddressesPage ? 'w-full' : 'lg:w-2/3'}>
            <button
              type="button"
              className="mt-6 bg-[transparent] outline-none border-2 border-primary-medium focus:ring-0 relative w-full flex justify-center px-6 py-3 items-center  uppercase font-bold text-sm rounded  flex-row text-primary-medium"
              onClick={() => {
                reset();
                onFinish && onFinish({ newAddressId: false });
              }}
            >
              {isCustomerAddressesPage ? 'Cancelar' : 'Voltar'}
            </button>
          </div>
        )}
      </div>
      <div
        className={
          isCustomerAddressesPage
            ? 'col-span-12 md:col-span-3'
            : 'col-span-12 md:col-span-6'
        }
      >
        <div
          className={isCustomerAddressesPage ? 'w-full' : 'lg:w-2/3 ml-auto'}
        >
          <button
            type="submit"
            className="lg:mt-6 bg-primary-medium group border-2 border-primary-medium hover:outline-none focus:border-transparent focus:ring-0 relative w-full flex justify-center hover:bg-seconday-dark focus:outline-none px-6 py-3 items-center  uppercase font-bold text-sm rounded flex-row text-white hover:bg-primary-dark disabled:bg-primary-light"
          >
            {data?.id
              ? 'Atualizar'
              : isCustomerAddressesPage
                ? 'Salvar'
                : 'Continuar'}
          </button>
        </div>
      </div>
    </form>
  );
};
