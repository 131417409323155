import CreditCardSvg from '@assets/icons/credit-card.svg';
import { useCart } from '@hooks';

import { priceFormat } from '@utils';
import { useMemo } from 'react';
import { BillingAddressForm } from '../BillingAddressForm';
import { GenericForm } from '../formGeneric';
import { useCheckout } from 'src/hooks/useCheckout';
import { PaymentMethodType } from '@contexts/checkout';

export const Deuna = () => {
  const { cart } = useCart();
  const { paymentMethodList } = useCheckout();

  const deunaSettings = useMemo(() => {
    const deunaPaymentMethod = paymentMethodList?.find(
      paymentMethod => paymentMethod.code === 'deuna_paymentlink'
    );

    if (deunaPaymentMethod) return deunaPaymentMethod;

    return {} as PaymentMethodType;
  }, [paymentMethodList]);

  const price = useMemo(() => {
    const value = cart?.prices?.grand_total.value;
    if (value) return priceFormat({ value });
  }, [cart]);

  return (
    <GenericForm>
      <div className="w-full border-[1px] border-gray-light rounded bg-gray-lightest p-6 mb-4">
        <div id="validate " className="w-full">
          <div className="w-full flex justify-center items-center my-1">
            <span className="mx-2">
              <CreditCardSvg color="#19191E" />
            </span>
            <span className="mx-1 font-bold normal text-xl text-gray-darkest">
              {price}
            </span>
            <span className="mx-2 text-base font-normal text-gray-darkest text-nowrap">
              à vista
            </span>
          </div>

          {deunaSettings?.instructions && (
            <div className="mt-2 flex items-center justify-center">
              <span className="text-base font-normal text-gray-darkest text-nowrap">
                {deunaSettings?.instructions}
              </span>
            </div>
          )}
        </div>
      </div>

      <BillingAddressForm />
    </GenericForm>
  );
};
