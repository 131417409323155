import BarCodeSVG from '@assets/icons/barcode.svg';
import { useCart } from '@hooks';
import { priceFormat } from '@utils';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingAddressForm } from '../BillingAddressForm';
import { GenericForm } from '../formGeneric';

export interface BoletoBancarioProps {
  expiration?: string;
}

export const BoletoBancario = ({ expiration }) => {
  const { cart } = useCart();

  const price = useMemo(() => {
    const value = cart?.prices?.grand_total.value;
    if (value) return priceFormat({ value });
  }, [cart]);

  return (
    <GenericForm>
      <div className="w-full border-[1px] border-gray-light rounded bg-gray-lightest p-6 my-4">
        <div id="price"></div>
        <div id="validate " className="w-full">
          <div className="w-full flex justify-center items-center my-1">
            <span className="mx-2">
              <BarCodeSVG color="#19191E" />
            </span>
            <span className="mx-1 font-bold normal text-xl text-gray-darkest">
              {price}
            </span>
            <span className="mx-2 text-base font-normal text-gray-darkest text-nowrap">
              à vista
            </span>
          </div>
          <div className="w-full flex justify-center my-1">
            <span className="normal font-normal text-sm text-gray-dark max-w-40 text-center lg:max-w-none">
              <FormattedMessage
                id="checkoutPage.paymentBilletExpiration"
                values={{ count: Number(expiration || 1) }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="my-4">
        <span className="font-normal text-sm text-gray-dark">
          O prazo de entrega passará a valer a partir do momento em que o banco
          confirmar o pagamento do boleto. O processo poderá levar até 3 dias
          úteis.
        </span>
      </div>
      <div className="w-full">
        <BillingAddressForm />
      </div>
    </GenericForm>
  );
};
