import CheckSVG from '@assets/icons/check.svg';
import { useCallback, useMemo } from 'react';

export interface CheckBoxProps {
  id: string;
  onChange(checked: boolean): void;
  defaultChecked?: boolean;
  checked: boolean;
  name: string;
}

export const Checkbox = ({ checked, name, id, onChange }: CheckBoxProps) => {
  const iconRender = useMemo(() => {
    return checked ? <CheckSVG color="#244ACF" /> : null;
  }, [checked]);

  const change = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);

  return (
    <>
      <input
        id={id}
        name={name}
        type="checkbox"
        style={{ display: 'none' }}
        checked={checked}
        onChange={change}
      />
      <span
        id={id}
        className="block w-[fit-content] bg-primary-lightest border-[1px] border-primary-medium rounded cursor-pointer"
        onClick={() => {
          onChange(!checked);
        }}
      >
        <span className="block p-1">
          <span className="h-[8px] w-[8px] flex justify-center items-center">
            {iconRender}
          </span>
        </span>
      </span>
    </>
  );
};
