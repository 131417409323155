import React from 'react';
import creditCardType, { types, getTypeInfo } from 'credit-card-type';
import { X as XIcon } from 'react-feather';
import cardValidator from 'card-validator';
import { CustomImage } from '@components/common';

export function getCardInfo(number) {
  const defaultInfo = {
    brandLogo: null,
    numberLength: 19,
    code: {
      name: 'CVV',
      size: 4
    },
    formatter: getCardFormatter(types.VISA)
  };

  if (number && number.length > 4) {
    const type = getCardType(number);

    const cardInfo = getTypeInfo(type);

    if (cardInfo) {
      return {
        brandLogo: getCardLogo(type),
        numberLength: cardInfo.lengths[0] + cardInfo.gaps.length,
        code: cardInfo.code,
        formatter: getCardFormatter(type)
      };
    }

    return { ...defaultInfo, brandLogo: getCardLogo('') };
  }

  return defaultInfo;
}

export function getCardType(number) {
  const arrayTypes = creditCardType(number.replace(/\D/g, ''));
  const type = arrayTypes.length > 0 ? arrayTypes[0].type : '';

  return type;
}

export function getCardLogo(type) {
  switch (type) {
    case types.VISA:
      return (
        <CustomImage
          className="mx-5 mt-5 h-10 w-14"
          src="/assets/icons/logo-visa.svg"
          alt=""
        />
      );
    case types.MASTERCARD:
      return (
        <CustomImage
          className="mx-5 mt-5 h-10 w-14"
          src="/assets/icons/logo-mastercard.svg"
          alt=""
        />
      );
    case types.AMERICAN_EXPRESS:
      return (
        <CustomImage
          className="mx-5 mt-5 h-10 w-14"
          src="/assets/icons/logo-american.svg"
          alt=""
        />
      );
    case types.DINERS_CLUB:
      return (
        <CustomImage
          className="mx-5 mt-5 h-10 w-14"
          src="/assets/icons/logo-diners.svg"
          alt=""
        />
      );
    case types.HIPERCARD:
      return (
        <CustomImage
          className="mx-5 mt-5 h-10 w-14"
          src="/assets/icons/logo-hipercard.svg"
          alt=""
        />
      );
    default:
      return (
        <div className="mx-5 mt-5 h-10 w-14">
          <XIcon size={40} />
        </div>
      );
  }
}

export function getCardFormatter(type) {
  const common = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  const amex = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  return type === types.AMERICAN_EXPRESS ? amex : common;
}

export function getCardCodeFormatter(length) {
  return length === 3 ? [/\d/, /\d/, /\d/] : [/\d/, /\d/, /\d/, /\d/];
}

export function validStringOnly(e) {
  let char = String(e.key);
  let pattern = /[\p{Letter}\p{Mark}'\s]+/gu;
  if (!char.match(pattern)) {
    e.preventDefault();
  }
}

const SUCCESS = undefined;

/**
 * Checks if a string is a valid credit card number.
 * @param {string} value string to be validated
 * @returns {{ id: string, defaultMessage: string } | undefined} a message when it's not valid and undefined otherwise
 */
export const validateCreditCard = cardNumber => {
  const message = {
    id: 'validation.validateCardNumber',
    defaultMessage: 'Número do cartão inválido.'
  };

  //It's validating with card-validator because validator lib doesn't check for potentially valid number
  const { isPotentiallyValid } = cardValidator.number(cardNumber);

  if (!isPotentiallyValid) {
    return message;
  }

  return SUCCESS;
};

/**
 * Checks if a string is a valid credit card expiration date.
 * @param {string} expirationDate date to be validated
 * @returns {{ id: string, defaultMessage: string } | undefined} a message when it's not valid and undefined otherwise
 */
export function validateCCExpirationDate(expirationDate) {
  const message = {
    id: 'validation.validateCardExpirationDate',
    defaultMessage: 'Cartão vencido ou data inválida.'
  };
  const { isValid } = cardValidator.expirationDate(expirationDate);

  if (!isValid) {
    return message;
  }
}

/**
 * Checks if a string is a valid credit card code.
 * @param {string} value code to be validated
 * @param {string} length code length to be validated
 * @returns {{ id: string, defaultMessage: string } | undefined} a message when it's not valid and undefined otherwise
 */
export function validateCCCode(value, length) {
  const message = {
    id: 'validation.validateCardCode',
    defaultMessage: 'Código de verificação do cartão inválido.'
  };

  const { isValid } = cardValidator.cvv(value, length);

  if (!isValid) {
    return message;
  }

  return SUCCESS;
}

export function validateCCCodeSignaturePage(value) {
  const isAmex = value?.length > 3;

  if (isAmex) {
    return validateCCCode(value, 4);
  }

  return validateCCCode(value, 3);
}
