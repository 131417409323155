import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { ErrorMessage, Select, Input } from '@components/common';
import ArrowSVG from '@assets/icons/arrow-point-left.svg';
import axios from 'axios';
import {
  useCreateCustomerAddressMutation,
  useGetCustomAddressTypeQueryQuery,
  useGetRegionsQuery
} from '@generated/graphql';
import { useCheckout } from 'src/hooks/useCheckout';
import { toast } from 'react-hot-toast';
import { customAddressQueryParams } from '@components/common/CustomerAddress/Form';
import { Checkbox } from '@components/common/Checkbox';

const getAddressForCep = (cep: string) => {
  return axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
};

export const NewBillingAddressForm = ({
  onFinish
}: {
  onFinish?: () => any;
}) => {
  const {
    setBillingAddress,
    newBillingAddressFormValues,
    setNewBillingAddressFormValues
  } = useCheckout();
  const [createAddress] = useCreateCustomerAddressMutation();

  // const [toAddressList, setToAddressList] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    trigger
  } = useForm({
    defaultValues: newBillingAddressFormValues
  });

  const noNumberData = watch('noNumber');
  const { data: regions } = useGetRegionsQuery({
    variables: {
      countryCode: 'BR'
    }
  });
  const { data: addressTypes } = useGetCustomAddressTypeQueryQuery({
    variables: customAddressQueryParams as never,
    onCompleted: addressTypes => {
      setValue(
        'custom_address_type',
        addressTypes?.customAttributeMetadata.items[0].attribute_options[0]
      );
    }
  });

  const regionsOptions = useMemo(
    () => regions?.country.available_regions || [],
    [regions?.country.available_regions]
  );

  const customerAddressTypeOptions = useMemo(
    () =>
      addressTypes?.customAttributeMetadata.items[0].attribute_options || [],
    [addressTypes?.customAttributeMetadata.items]
  );

  const postcodeHandler = useCallback(
    (val: string) => {
      const cep = val.replaceAll('_', '').replaceAll('-', '');
      if (cep.length === 8) {
        const regionValue = (code: string) => {
          const reg = regionsOptions.find(item => item.code === code);

          return { value: reg?.id, label: reg?.name };
        };

        getAddressForCep(cep).then(res => {
          new Map(
            Object.entries({
              city: res.data?.city,
              street: res.data?.street,
              district: res.data?.neighborhood,
              state: regionValue(res.data.state)
            })
          ).forEach((val, key) => {
            setValue(key, val);
          });
        });
      }
    },
    [regionsOptions, setValue]
  );

  const handleSubmitNewAddress = useCallback(
    async (data: any) => {
      try {
        const regionFound = regionsOptions.find(
          item => item.id === data.state.value
        );

        if (regionFound) {
          const region = {
            region: regionFound.name,
            region_code: regionFound.code,
            region_id: regionFound.id
          };

          await createAddress({
            variables: {
              address: {
                id: data?.id,
                firstname: data.firstname,
                lastname: data.lastname,
                street: [
                  data.street,
                  data?.noNumber ? 'sn' : data.number,
                  data.complement,
                  data.district
                ],
                city: data.city,
                company: '',
                postcode: data.postcode.replaceAll('_', '').replaceAll('-', ''),
                telephone: data.telephone,
                country_code: 'BR',
                custom_address_type: data.custom_address_type.value,
                region,
                default_shipping: false,
                default_billing: false
              } as any
            },
            onCompleted: async ({
              createCustomerAddress: { id: newAddressId }
            }) => {
              await setBillingAddress({
                sameAsShipping: false,
                customerAddressId: newAddressId
              });

              setNewBillingAddressFormValues(data);
              toast.success('Endereço de cobrança cadastrado com sucesso!');
              if (onFinish) onFinish();
            }
          });
        }
      } catch (err) {
        toast.error(err);
      }
    },
    [
      createAddress,
      onFinish,
      regionsOptions,
      setBillingAddress,
      setNewBillingAddressFormValues
    ]
  );

  return (
    <form className="width-full pb-4 border-gray-light p-0">
      <div className="width-full grid grid-cols-12 md:gap-x-12 gap-y-6">
        <div className="col-span-12">
          <div className="mt-[15px] mb-[2.4px] text-left text-sm font-light text-gray-dark">
            <label>Tipo de Endereço</label>
          </div>

          <Controller
            name="custom_address_type"
            control={control}
            rules={{ required: 'Este é um campo obrigatório.' }}
            render={({ field }) => (
              <Select
                {...field}
                options={customerAddressTypeOptions}
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: errors.custom_address_type && 'red!important'
                  })
                }}
              />
            )}
          />
        </div>

        <div className="col-span-12 md:col-span-6">
          <div className="mt-[15px] text-left text-sm font-light text-gray-dark">
            <label>CEP</label>
          </div>
          <div className="flex w-full">
            <Controller
              name="postcode"
              control={control}
              rules={{
                required: 'Este é um campo obrigatórios'
              }}
              render={({ field }) => {
                return (
                  <InputMask
                    {...field}
                    className={`outline-none px-3 py-1 h-12 w-full rounded border border-gray-medium focus:border-primary-light bg-primary-lightest ${
                      errors.postcode
                        ? 'border-error-medium focus:active:border-error-medium'
                        : 'border-gray-medium '
                    }`}
                    type="text"
                    id="postcode"
                    mask="99999-999"
                  />
                );
              }}
            />

            <button
              type="button"
              className="h-[inherit] w-[auto] bg-[#009CDE] rounded mr-2"
              onClick={() => {
                postcodeHandler(getValues()['postcode']);
              }}
            >
              <div className="rotate-180 px-4">
                <ArrowSVG color="#ffffff" />
              </div>
            </button>
          </div>

          {errors.postcode ? (
            <>
              {errors?.postcode && (
                <ErrorMessage error="Este é um campo obrigatório." />
              )}
            </>
          ) : null}
        </div>
        <div className="col-span-6">
          <div className="h-[100%] flex items-center relative top-[16px]">
            <a
              className="text-primary-medium font-bold cursor-pointer"
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              rel="noreferrer"
            >
              Não sabe seu cep?
            </a>
          </div>
        </div>
        <div className="col-span-12">
          <Input
            type="text"
            label="Endereço"
            id="street"
            register={register}
            errors={errors}
            validations={{
              required: 'Este é um campo obrigatório.'
            }}
          />
        </div>

        <div className="col-span-12 md:col-span-6">
          <Input
            type="number"
            label="Número"
            id="number"
            min={0}
            register={register}
            disabled={noNumberData}
            validations={{
              required: noNumberData ? '' : 'Este é um campo obrigatório.'
            }}
            errors={errors}
          />
        </div>
        <div
          className={`col-span-12 md:col-span-6 ${errors?.number ? 'my-auto' : 'mt-auto'} h-[48px]`}
        >
          <div className="flex items-center h-full">
            <Controller
              name="noNumber"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={!!field.value}
                  onChange={isNoNumber => {
                    if (isNoNumber) {
                      setValue('number', 'sn');
                    } else {
                      setValue('number', '');
                    }

                    setValue('noNumber', !field.value);
                    trigger();
                  }}
                  id="noNumber"
                />
              )}
            />
            <div className="ml-2">
              <label htmlFor="noNumber">Sem número</label>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            type="text"
            label="Complemento (opcional)"
            id="complement"
            maxLength={50}
            register={register}
            validations={{}}
            errors={errors}
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            type="text"
            label="Bairro"
            id="district"
            register={register}
            validations={{ required: 'Este é um campo obrigatório.' }}
            errors={errors}
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            type="text"
            label="Cidade"
            id="city"
            register={register}
            validations={{ required: 'Este é um campo obrigatório.' }}
            errors={errors}
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="mt-[15px] text-left text-sm font-light text-gray-dark">
            <label>Estado</label>
          </div>
          <Controller
            name="state"
            control={control}
            rules={{
              required: 'Este é um campo obrigatório'
            }}
            render={({ field }) => (
              <Select
                {...field}
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: errors.state && 'red!important'
                  })
                }}
                options={regionsOptions.map(item => ({
                  value: item.id,
                  label: item.name
                }))}
              />
            )}
          />
          {errors.state ? (
            <>
              {errors?.state && (
                <ErrorMessage error="Este é um campo obrigatório." />
              )}
            </>
          ) : null}
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="mt-[15px] text-left text-sm font-light text-gray-dark">
            <label>Telefone</label>
          </div>
          <InputMask
            className={`outline-none px-3 py-1 h-12 w-full rounded border border-gray-medium focus:border-primary-light bg-primary-lightest ${
              errors.telephone
                ? 'border-error-medium focus:active:border-error-medium'
                : 'border-gray-medium '
            }`}
            mask="(99) 99999-9999"
            type="text"
            {...register('telephone', {
              required: 'Este é um campo obrigatório.'
            })}
          />
          {errors.telephone ? (
            <>
              {errors?.telephone && (
                <ErrorMessage error="Este é um campo obrigatório." />
              )}
            </>
          ) : null}
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            type="text"
            label="Nome"
            id="firstname"
            register={register}
            validations={{ required: 'Este é um campo obrigatório.' }}
            errors={errors}
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <Input
            type="text"
            label="Sobrenome"
            id="lastname"
            register={register}
            validations={{ required: 'Este é um campo obrigatório.' }}
            errors={errors}
          />
        </div>
        {/* <div className="col-span-12">
          <div className="flex items-center py-2 my-2">
            <div className="mr-2">
              <Checkbox
                id="to_address_list"
                name="to_address_list"
                checked={toAddressList}
                onChange={val => {
                  setToAddressList(val);
                }}
              />
            </div>
            <div className="ml-2">
              <label
                htmlFor="to_address_list"
                className="normal font-normal text-base text-gray-dark"
                onClick={() => {
                  setToAddressList(state => !state);
                }}
              >
                Adicionar à lista de endereços
              </label>
            </div>
          </div>
        </div> */}
        <div className="col-span-12">
          <div className="w-full flex justify-end">
            <div className="w-[50%]">
              <button
                type="button"
                className="mt-6 bg-secondary-medium group border-2 border-secondary-medium hover:outline-none focus:border-transparent focus:ring-0 relative w-full flex justify-center hover:bg-seconday-dark focus:outline-none px-6 py-4 items-center  uppercase font-bold text-sm rounded flex-row text-white hover:bg-secondary-dark disabled:bg-secondary-light"
                onClick={handleSubmit(handleSubmitNewAddress)}
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
