import classNames from 'classnames';
import { ReactFragment, ReactNode, useCallback, useEffect } from 'react';

interface Props {
  inputId: string;
  name: string;
  currentValue: string | number;
  value: string | number;
  labelDiscount: string;
  onChange(value: string | number): void;
  label: string;
  children?: ReactNode | ReactFragment;
  icon?: () => ReactNode;
}

export const PaymentMethodItem = ({
  currentValue,
  value,
  inputId,
  onChange,
  label,
  icon,
  labelDiscount
}: Props) => {
  const checked = useCallback(() => {
    return currentValue === value;
  }, [currentValue, value]);

  useEffect(() => {
    const element = document.getElementById(inputId + '-container');
    if (!element) return;
  }, [checked, inputId]);

  return (
    <div
      id={inputId + '-container'}
      onClick={() => onChange(value)}
      className={classNames(
        'h-full min-h-[140px] w-[120px] flex flex-col justify-center items-center relative cursor-pointer rounded-md px-2 py-4',
        checked()
          ? 'border border-primary-medium bg-primary-lightest'
          : 'border border-gray-light bg-white-lightest'
      )}
    >
      <span
        id="icon"
        className={classNames(
          'flex items-center justify-center w-8 h-[24px]',
          checked() ? 'text-primary-medium' : 'text-gray-medium'
        )}
      >
        {icon && icon()}
      </span>

      <span className="text-sm text-gray-darkest text-center py-2">
        {label}
      </span>

      {labelDiscount && (
        <span className="px-1 text-[12px] font-bold text-success-dark border bg-success-lightest border-success-light rounded-sm">
          {labelDiscount}
        </span>
      )}
    </div>
  );
};
