import PixSVG from '@assets/icons/pix.svg';
import { useCart } from '@hooks';
import { priceFormat } from '@utils';
import { useMemo } from 'react';
import { BillingAddressForm } from '../BillingAddressForm';
import { GenericForm } from '../formGeneric';

export const Pix = () => {
  const { cart } = useCart();

  const price = useMemo(() => {
    const value = cart?.prices?.grand_total.value;
    if (value) return priceFormat({ value });
  }, [cart]);

  return (
    <GenericForm>
      <div className="w-full border-[1px] border-gray-light rounded bg-gray-lightest p-6 my-4">
        <div id="price"></div>
        <div id="validate " className="w-full">
          <div className="w-full flex justify-center items-center my-1">
            <div className="mx-2">
              <PixSVG color="#525252" />
            </div>
            <span className="mx-1 font-bold normal text-xl text-gray-darkest">
              {price}
            </span>
            <span className="mx-2 text-base font-normal text-gray-darkest text-nowrap">
              à vista
            </span>
          </div>
        </div>
      </div>
      <div className="my-4">
        <span className="font-normal text-sm text-gray-dark">
          Após finalizar o pedido será gerado o código para pagamento por PIX
          que ficará disponível por 15 minutos.
        </span>
      </div>
      <div className="w-full">
        <BillingAddressForm />
      </div>
    </GenericForm>
  );
};
